import styled from 'styled-components';

import { baseFontFamily, baseFontSize, baseLineHeight, numberFontFamily, numberFontSize } from 'views/services/font-scheme';
import { baseColor, darkBaseColor, textColor, lightTextColor, darkTextColor, lineColor, primaryLineColor, lightPrimaryColor, darkPrimaryColor, vividPrimaryColor, primaryTextColor, secondaryLineColor, secondaryTextColor, darkSecondaryColor, lightAccentColor, vividAccentColor } from 'views/services/color-scheme';

import { headerHeight } from 'views/components/header';

const columnWidth = '440px';

interface ColumnNumber {
  columnNumber: number;
}

export const Root = styled.div`
`;

export const Frame = styled.div`
  width: 100vw;
  height: calc(100vh - ${headerHeight});
  overflow: scroll;
  background-color: ${darkBaseColor};
`;

export const Categories = styled.div<ColumnNumber>`
  z-index: 10;
  position: sticky;
  top: 0;
  background-color: ${darkPrimaryColor};
  & > ol {
    font-family: ${baseFontFamily};
    font-size: calc(${baseFontSize} * 1);
    width: calc(${columnWidth} * ${p => p.columnNumber});
    height: ${headerHeight};
    display: flex;
    & > li {
      flex: 1;
      padding: 0 16px;
      height: 100%;
      background-color: ${darkPrimaryColor};
      color: ${primaryTextColor};
      position: relative;
      &::after {
        content: '';
        pointer-events: none;
        position: absolute;
        top: -0.5px;
        left: -0.5px;
        right: -0.5px;
        bottom: -0.5px;
        border-width: 0 1px;
        border-style: solid;
        border-color: ${primaryLineColor};
      }
      &.off-menu {
        background-color: ${darkSecondaryColor};
        color: ${secondaryTextColor};
        &::after {
          border-color: ${secondaryLineColor};
        }
      }
      display: flex;
      align-items: center;
    }
  }
`;

export const Items = styled.div<ColumnNumber>`
  z-index: 0;
  & > ol {
    background-color: ${darkBaseColor};
    width: calc(${columnWidth} * ${p => p.columnNumber});
    display: flex;
    justify-content: space-between;
    align-items: start;
    & > li {
      flex: 1;
    }
  }
`;

export const ItemHeader = styled.div`
  padding: 24px 16px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > h3 {
    font-family: ${baseFontFamily};
    font-size: calc(${baseFontSize} * 1);
    color: ${textColor};
  }
  & > div {
    font-family: ${baseFontFamily};
    font-size: calc(${baseFontSize} * 0.75);
    color: ${lightTextColor};
    max-width: calc(${columnWidth} / 4);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const ItemFooter = styled.div`
  padding: 8px 16px 16px;
  & > table {
    font-family: ${baseFontFamily};
    font-size: calc(${baseFontSize} * 0.8);
    line-height: calc(${baseLineHeight} + 0.2);
    color: ${textColor};
    width: 100%;
    & > thead > tr > th {
      width: calc(100% / 3);
      color: ${lightTextColor};
      text-align: center;
    }
    & > tbody > tr > td {
      font-family: ${numberFontFamily};
      font-size: calc(${numberFontSize} * 1);
      text-align: right;
    }
  }
`;

export const Item = styled.div`
  background-color: ${baseColor};
  cursor: pointer;
  position: relative;
  &::after {
    content: '';
    pointer-events: none;
    z-index: 1;
    position: absolute;
    top: -0.5px;
    left: -0.5px;
    right: -0.5px;
    bottom: -0.5px;
    border: 1px solid ${lineColor};
  }
  &.ingredient-item {
    background-color: ${lightAccentColor};
    &::after {
      z-index: 2;
      border: 1px solid ${vividAccentColor};
    }
  }
  &:hover {
    background-color: ${lightPrimaryColor};
    &::after {
      z-index: 3;
      border: 1px solid ${vividPrimaryColor};
    }
  }
`;

export const Ingredients = styled.div`
  padding: 8px 16px;
  & > table {
    font-family: ${baseFontFamily};
    font-size: calc(${baseFontSize} * 0.8);
    line-height: calc(${baseLineHeight} + 0.2);
    color: ${textColor};
    width: 100%;
    & > thead > tr > th {
      color: ${lightTextColor};
      &.center {
        text-align: center;
      }
    }
    & > tbody > tr > td {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      &.text {
        max-width: 120px;
      }
      &.right {
        text-align: right;
      }
      &.number {
        font-family: ${numberFontFamily};
        font-size: calc(${numberFontSize} * 0.8);
        text-align: right;
      }
      &.none {
        color: ${darkTextColor};
      }
    }
  }
`;
